<template>
  <div class="co-flex-col">
    <el-upload
      id="wdUploadImgs"
      :ref="wdImg.name"
      :action="wdImg.action"
      multiple
      :show-file-list="false"
      list-type="picture"
      :accept="wdImg.accept"
      :limit="wdImg.limit"
      :headers="wdImg.headers"
      :on-success="onImageSuccess"
      :on-change="onImageChange"
      :on-progress="onImageProgress"
      :on-error="onImageError"
      :before-upload="onImageBeforeUpload"
      :file-list="wdImg.files"
      :auto-upload="wdImg.autoUpload"
      style="display: none;"
    >
      上传
    </el-upload>
    <v-row
      :dense="dense"
    >
      <v-col
        v-for="(item, i) in upImages"
        :key="`img_${i}`"
        cols="12"
        :md="colNum"
      >
        <v-hover
          v-slot="{ hover }"
          close-delay="100"
        >
          <v-card
            :elevation="hover ? hoverElevation : normalElevation"
            class="rounded transition-swing"
            color="white"
            flat
            tile
          >
            <v-img
              :aspect-ratio="ratio"
              :src="item.url"
              height="inherit"
            >
              <div
                class="co-flex-col co-justify-between co-items-center co-w-full co-h-full"
              >
                <upload-state :item="item" />
                <upload-actions
                  v-if="hover"
                  :item="item"
                  :index="i"
                  @on-preview="onImagePreview"
                  @on-remove="onImageRemove"
                />
                <upload-progress
                  :value="upProgress[i].value"
                />
              </div>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
      <v-col
        v-if="canUpload"
        cols="12"
        :md="colNum"
      >
        <v-hover
          v-slot="{ hover }"
          close-delay="100"
        >
          <v-card
            class="rounded"
            color="white"
            flat
            tile
            height="100%"
          >
            <v-img
              :aspect-ratio="ratio"
              :src="wdUpload.bg"
              height="inherit"
            >
              <div
                class="co-flex-col co-justify-center co-items-center co-h-full co-border co-border-dash"
                :class="[hover ? 'co-border-major' : 'co-border-gray-500']"
                style="cursor: pointer;"
                @click="toSelectImg"
              >
                <v-icon
                  size="48"
                  color="blue-grey lighten-4"
                >
                  {{ wdUpload.icon }}
                </v-icon>
                <span
                  class="co-text-sm co-text-gray-500"
                >
                  {{ wdUpload.text }}
                </span>
              </div>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <el-dialog
      :title="dlgView.title"
      :visible.sync="dlgView.visible"
    >
      <img
        width="100%"
        :src="dlgView.imgUrl"
      >
    </el-dialog>
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  export default {
    components: {
      UploadState: () => import('@/pages/sections/my/UploadState.vue'),
      UploadActions: () => import('@/pages/sections/my/UploadActions.vue'),
      UploadProgress: () => import('@/pages/sections/my/UploadProgress.vue')
    },
    props: {
      config: {
        type: Object,
        default: () => {
          return {
            ratio: 1,
            numberPerRow: 4,
            limit: 9
          }
        }
      }
    },
    data () {
      return {
        wdImg: {
          name: 'wdUploadImgs',
          action: `${api.url.web_util_file_upload}?folder=image`,
          accept: '.jpg,.jpeg,.png,.gif',
          autoUpload: false,
          tip: '只能上传jpg/png/gif文件，且每个文件不能超过20MB',
          limit: 50,
          count: 0,
          headers: {},
          files: [],
          items: [],
        },
        ratio: 1,
        colNum: 3,
        dense: false,
        upImages: [],
        upProgress: [],
        normalElevation: 0,
        hoverElevation: 18,
        wdUpload: {
          icon: 'mdi-plus',
          text: '上传图片',
          bg: app.pic.img_upload_bg
        },
        dlgView: {
          title: '图片预览',
          visible: false,
          imgUrl: ''
        }
      }
    },
    computed: {
      canUpload () {
        let result = true
        if (this.wdImg.limit < 1) {
          result = true
        } else {
          if (this.wdImg.count < this.wdImg.limit) {
            result = true
          } else {
            result = false
          }
        }
        return result
      }
    },
    watch: {
      config: {
        handler (val) {
          this.ratio = val.ratio || 1
          this.dense = !!val.dense
          const numPerRow = parseInt(val.numberPerRow, 10) || 4
          this.colNum = Math.floor(12 / numPerRow)

          this.wdImg.limit = parseInt(val.limit, 10) || 0
        },
        immediate: true
      }
    },
    created () {
      this.configHeaders()
    },
    methods: {
      changeCount () {
        const cnt = this.wdImg.items.length
        this.wdImg.count = cnt
      },
      getProgressIndex (uid) {
        let result = -1
        const cnt = this.upProgress.length
        for (let i = 0; i < cnt; i++) {
          const src = this.upProgress[i]
          if (src.uid === uid) {
            result = i
            break
          }
        }
        return result
      },
      getImageIndex (file) {
        let result = -1
        const cnt = this.upImages.length
        for (let i = 0; i < cnt; i++) {
          const src = this.upImages[i]
          if (src.uid === file.uid) {
            result = i
            break
          }
        }
        return result
      },
      getImageIndexFromSrc (file) {
        let result = -1
        const cnt = this.wdImg.items.length
        for (let i = 0; i < cnt; i++) {
          const src = this.wdImg.items[i]
          if (src.uid === file.uid) {
            result = i
            break
          }
        }
        return result
      },
      setProgress (uid, value = 0) {
        const ind = this.getProgressIndex(uid)
        const dst = {
          uid, value
        }

        if (ind < 0) {
          this.upProgress.push(dst)
        } else {
          this.$set(this.upProgress, ind, dst)
        }
      },
      setImage (file) {
        let status = file.status
        const response = file.response || {}
        if (status === 'success') {
          if (response.code !== 200) {
            status = 'error'
            const srcInd = this.getImageIndexFromSrc(file)
            if (srcInd >= 0) {
              const src = this.wdImg.items[srcInd]
              src.status = 'ready'
              this.$set(this.wdImg.items, srcInd, src)
            }
          }
        }
        const dst = {
          uid: file.uid,
          name: file.name,
          status,
          url: file.url,
          percent: file.percentage,
          size: file.size,
          response
        }
        const ind = this.getImageIndex(file)
        if (ind < 0) {
          this.upImages.push(dst)
        } else {
          this.$set(this.upImages, ind, dst)
        }
      },
      toSelectImg () {
        document.querySelector('#wdUploadImgs .el-upload').click()
      },
      configHeaders () {
        const obj = user.token.get()
        const headers = {
          token: obj.accessToken,
          uuid: obj.userId
        }
        this.wdImg.headers = headers
      },
      onImageBeforeUpload (file) {
      },
      onImageProgress (ev, file, fileList) {
        const uid = file.uid
        const value = Math.floor(ev.percent)
        this.setProgress(uid, value)
      },
      onImageError (ev, file, fileList) {
        console.log('onImageError')
      },
      onImageSuccess (resp, file, fileList) {

      },
      onImagePreview (ind, file) {
        this.dlgView.imgUrl = file.url
        this.dlgView.visible = true
      },
      onImageRemove (ind, file) {
        this.wdImg.items.splice(ind, 1)
        this.upImages.splice(ind, 1)
        this.changeCount()
      },
      onImageChange (file, fileList) {
        this.wdImg.items = fileList
        this.setImage(file)
        this.setProgress(file.uid, file.percentage)
        this.changeCount()
        this.$emit('change', {
          count: this.wdImg.count,
          limit: this.wdImg.limit,
          files: this.wdImg.items,
          items: this.upImages
        })
      },
      clear () {
        const frm = this.$refs[this.wdImg.name]
        if (frm) {
          frm.clearFiles()
        }

        this.upImages = []
        this.upProgress = []
        this.wdImg.count = 0
      },
      submit () {
        const frm = this.$refs[this.wdImg.name]
        if (frm) {
          frm.submit()
        }
      }
    }
  }
</script>
